import Highway from '@dogstudio/highway';
import Animation from "../../animation";

export default class ContactRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    Animation.init();
  }
  onLeave() {

  }
  onEnterCompleted() {
    Animation.observe();
  }
  onLeaveCompleted() {

  }
}
