import Animation from "./animation";
import { gsap } from "gsap";

export default class Menu{
  constructor(){
    this.burger = document.querySelector('.burger');
    this.burgerCut01 = document.querySelector('.burger-cut-01');
    this.burgerCut02 = document.querySelector('.burger-cut-02');
    this.menu = document.getElementById('menu');

    this.burger.addEventListener('click', function (event) {
      if (this.burger.getAttribute('aria-expanded') == 'false'){
        this.open();
      }else{
        this.close();
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }.bind(this));
  }

  open(){
    this.burger.setAttribute('aria-expanded', true);

    this.burger.classList.remove('pointer-events-auto');
    this.burger.classList.add('pointer-events-none');

    this.burgerCut01.classList.remove('bg-black');
    this.burgerCut01.classList.add('bg-white');
    this.burgerCut02.classList.remove('bg-black');
    this.burgerCut02.classList.add('bg-white');

    this.menu.classList.remove('pointer-events-none');
    this.menu.classList.add('pointer-events-auto');

    document.querySelector('body').classList.add('menu-open');

    Animation.appear(document.querySelector('#menu-nav.appear-block'));
    Animation.appear(document.querySelector('#menu-contact.appear-block'));
    Animation.appear(document.querySelector('#block-language-switcher .appear-block'));

    gsap.to(this.menu, {
      opacity: 1,
      duration: .5,
      ease: "expo.out",
      onComplete: function(){
        document.querySelector('.burger').classList.remove('pointer-events-none');
        document.querySelector('.burger').classList.add('pointer-events-auto');
        window.lockScroll.lock();
      }
    });

  }

  close(){
    this.burger.setAttribute('aria-expanded', false);

    this.burgerCut01.classList.remove('bg-white');
    this.burgerCut01.classList.add('bg-black');
    this.burgerCut02.classList.remove('bg-white');
    this.burgerCut02.classList.add('bg-black');

    this.menu.classList.remove('pointer-events-auto');
    this.menu.classList.add('pointer-events-none');

    document.querySelector('body').classList.remove('menu-open');

    window.lockScroll.unlock();

    gsap.to(this.menu, {
      opacity: 0,
      duration: .5,
      ease: "expo.out"
    });
  }

}
