//import _ from 'lodash';
import './scss/app.scss';

// Plugins
import Menu from "./js/menu";
import Cursor from "./js/cursor";
import LockScroll from "./js/lockScroll";
import Zebra from "./js/zebra";
import Header from "./js/header";
import { gsap } from "gsap";
import CustomEase from "./js/gsap/src/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Touch from "./js/touch";
import Loading from "./js/loading";
import Highway from '@dogstudio/highway';
import 'pace-progressbar';

// Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";
import AboutRenderer from "./js/highway/renderer/about-renderer";
import StoriesRenderer from "./js/highway/renderer/stories-renderer";
import CaseRenderer from "./js/highway/renderer/case-renderer";
import ContactRenderer from "./js/highway/renderer/contact-renderer";

//Transitions
import Fade from "./js/highway/transition/fade";
import ToCase from "./js/highway/transition/toCase";
//import Matomo from "./js/matomo";
//import GDPR from "./js/gdpr";

// Init plugins
window.touch = new Touch();
window.menu = new Menu();
window.lockScroll = new LockScroll();
window.zebra = new Zebra();
window.header = new Header();
//new GDPR();
window.cursor = new Cursor();

gsap.registerPlugin(ScrollToPlugin, CustomEase);
CustomEase.create("explose", "M0,0 C0,0 0.10246,0.00078 0.16596,0.00696 0.21598,0.01182 0.24756,0.01622 0.29498,0.02868 0.33471,0.03913 0.36212,0.04886 0.39736,0.06779 0.42825,0.08439 0.45003,0.10042 0.47445,0.12511 0.499,0.14993 0.51442,0.17198 0.53127,0.20319 0.54962,0.23717 0.55883,0.26299 0.5695,0.30113 0.58218,0.34647 0.5871,0.37584 0.59301,0.42388 0.60486,0.52017 0.60238,0.57985 0.61531,0.67343 0.62003,0.7076 0.62782,0.72912 0.63966,0.76162 0.64982,0.78952 0.65769,0.80799 0.67329,0.83263 0.6916,0.86155 0.70616,0.88182 0.73044,0.90498 0.75431,0.92775 0.77489,0.94249 0.80426,0.95757 0.83547,0.9736 0.86033,0.98201 0.89511,0.98955 0.93318,0.9978 1,1 1,1 ");

// Highway config
const H = new Highway.Core({
    renderers: {
      homepage: HomeRenderer,
      about:AboutRenderer,
      stories: StoriesRenderer,
      case: CaseRenderer,
      contact: ContactRenderer,
      default: HomeRenderer
    },
    transitions: {
      name: Fade,
      default: Fade,
      contextual:{
        toCase: ToCase
      }
    }
});
H.on('NAVIGATE_OUT', ({ to, trigger, location }) => {
    window.menu.close();
    Loading.start();

});

// Get all menu links
const links = document.querySelectorAll('[role="navigation"] a');

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  window.scrollTo(0,0);
  Loading.stop();
  window.cursor.reset();

  // Check Active Link
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    // Clean class
    link.classList.remove('is-active');
    link.classList.add('bg-gradient-animation');

    // Active link
    if (location.href.indexOf(link.href) > -1) {
      link.classList.add('is-active');
      link.classList.remove('bg-gradient-animation');
    }
  }
});

H.on('NAVIGATE_END', ({ to, trigger, location }) => {
  //Matomo.trackPage(to.page.title, location.href);

  document.getElementById('block-language-switcher').querySelector('a[hreflang=fr]').href = to.view.dataset.hrefFr;
  document.getElementById('block-language-switcher').querySelector('a[hreflang=en]').href = to.view.dataset.hrefEn;
});

if (module.hot) {
  //module.hot.accept();
}
