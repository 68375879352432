import Highway from '@dogstudio/highway';
import Swiper from 'swiper';
import { gsap } from "gsap";
import charming from 'charming';
import Animation from "../../animation";
import Video from "../../video";

export default class CaseRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    Animation.init();
    Video.init();

    let caseSwiper = new Swiper('[data-router-view="case"] .swiper-container', {
      spaceBetween: 16,
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      touchStartPreventDefault: false,
      preventClicks: false,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: 'true',
      },
      breakpoints: {
        768: {
          spaceBetween: 64
        }
      }
    });

  }
  onLeave() {

  }
  onEnterCompleted() {

    Animation.observe();

    // Update body class
    document.body.classList.add(document.getElementsByTagName('article')[0].className);

  }
  onLeaveCompleted() {

    // Update body class
    document.body.classList.remove(document.getElementsByTagName('article')[0].className);

  }
}
