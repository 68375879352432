import charming from "charming";
import { gsap } from "gsap";

export default class Animation {
  static init(){

    // Lettering
    document.querySelectorAll('[data-router-view] .words').forEach(function(words) {
      words.querySelectorAll('.word').forEach(function(word){
        charming(word,{
          setClassName: function (index, letter) {
            if (letter != ' '){
              return 'letter inline-block opacity-0';
            }
            return 'opacity-0';
          }
        });
      });
    });

    // Appear block
    document.querySelectorAll('[data-router-view] .appear-block, #menu .appear-block').forEach(function(block) {
      if (block.querySelectorAll('.appear-block-child').length > 0){
        block.querySelectorAll('.appear-block-child').forEach(function(t){
          if (t.classList) t.classList.add('opacity-0');
        })
      }else{
        block.classList.add('opacity-0');
      }
    });

    // Appear image
    document.querySelectorAll('[data-router-view] .appear-img').forEach(function(image) {
      image.classList.add('opacity-0');
    });

  }

  static observe(){

    if ("IntersectionObserver" in window) {

      IntersectionObserver.prototype.POLL_INTERVAL = 100; // Time in milliseconds

      // Lettering
      let lazyWordsObserver = new IntersectionObserver(function (entries, observer) {
        let letters = [];
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            if(!entry.target.dataset.played){
              entry.target.dataset.played = true;
              letters = letters.concat(entry.target.querySelectorAll('span.letter'));
            }
          }
        });
        if (letters.length > 0){
          gsap.fromTo(letters, {
            opacity:0,
            y:20,
            rotation:-10,
          },{
            opacity:1,
            y:0,
            rotation:0,
            ease: "expo.out",
            duration:1,
            delay:.3,
            stagger:{
              amount:.2,
              from:0
            }
          });
        }
      });
      document.querySelectorAll('[data-router-view] .words').forEach(function(words) {
        lazyWordsObserver.observe(words);
      });

      // Appear block
      let lazyBlockObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            //Play Once
            if(entry.target.dataset.played) return;
            entry.target.dataset.played = true;
            Animation.appear(entry.target);
          }
        });
      });
      document.querySelectorAll('[data-router-view] .appear-block').forEach(function(block) {
        lazyBlockObserver.observe(block);
      });

    }

  }

  static appear(element = 0) {
    gsap.fromTo( (element.querySelectorAll('.appear-block-child').length > 0) ? element.querySelectorAll('.appear-block-child') : element, {
      opacity:0,
      y:30,
    },{
      opacity:1,
      y:0,
      rotation:0,
      ease: "expo.out",
      duration:1,
      delay:.25,
      stagger:{
        amount:.2,
        from:0
      }
    });
  }

}
