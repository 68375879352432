export default class Loading {
  static start(){
    document.getElementsByClassName('cursor')[0].classList.add('loading');
    document.getElementsByClassName('cursor')[0].classList.remove('link-hovered');
    document.getElementsByClassName('cursor')[0].classList.remove('case-hovered');
    document.getElementsByClassName('cursor')[0].classList.remove('video-play');
    document.getElementsByClassName('cursor')[0].classList.remove('video-pause');
  }

  static stop(){
    setTimeout(function(){
      document.getElementsByClassName('cursor')[0].classList.remove('loading');
      document.getElementsByClassName('cursor')[0].classList.remove('link-hovered');
      document.getElementsByClassName('cursor')[0].classList.remove('case-hovered');
      document.getElementsByClassName('cursor')[0].classList.remove('video-play');
      document.getElementsByClassName('cursor')[0].classList.remove('video-pause');
    },1000);

  }
}
