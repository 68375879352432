import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
import CustomEase from "../../gsap/src/CustomEase";

import charming from "charming";

export default class ToCase extends Highway.Transition {


  out({ from, trigger, done }) {

    window.zebra.lock();


    let originalTrigger = trigger;
    if (trigger.dataset.context == 'teaser'){
      trigger = trigger.querySelector('.cover');
    }

    /*Clone Trigger*/
    let cloneTrigger = trigger.cloneNode(true);
    let scrollTop = (((window.pageYOffset || document.documentElement.scrollTop)));
    let offsetTop = (trigger.offsetTop);

    cloneTrigger.style.position = 'fixed';
    cloneTrigger.style.zIndex = 40;
    cloneTrigger.style.left = trigger.getBoundingClientRect().left+"px";
    cloneTrigger.style.top = trigger.getBoundingClientRect().top+"px";
    cloneTrigger.style.width = trigger.getBoundingClientRect().width+"px";
    cloneTrigger.style.height = trigger.getBoundingClientRect().height+"px";

    /*
    if (originalTrigger.dataset.context == 'teaser'){
      cloneTrigger.style.width = trigger.getBoundingClientRect().width+400+"px";
      cloneTrigger.style.height = trigger.getBoundingClientRect().height+400+"px";
      cloneTrigger.style.left = trigger.getBoundingClientRect().left -200 +"px";
      cloneTrigger.style.top = trigger.getBoundingClientRect().top - 200 +"px";
    }*/

    cloneTrigger.style.opacity = 0;

    document.querySelector('body').appendChild(cloneTrigger);
    cloneTrigger.classList.add('pointer-events-none');
    cloneTrigger.classList.add('remove-when-loaded');

    let imageCover = cloneTrigger.querySelector('.cover img');
    imageCover.onload = function() {
      console.log('image loaded');
      if (originalTrigger.dataset.context == 'teaser'){
        gsap.to(from, {
          opacity:0,
          duration:.2
        });
      }

      window.zebra.setBlack();

      //Remove text if found it.
      gsap.to(cloneTrigger.querySelectorAll('h2,h3'), {
        opacity:0,
        y:20,
        ease: "explose",
        duration:.4,
        stagger:{
          amount:.05,
          from:0
        }
      });

      cloneTrigger.style.opacity = 1;

      gsap.to(cloneTrigger.querySelectorAll('.bg-black'), {
        opacity:.25,
        duration:.8,
      });

      gsap.to(cloneTrigger, {
        top:0,
        bottom:0,
        left:0,
        right:0,
        duration:.8,
        height:'100vh',
        width:'100vw',
        ease: "explose",
        onComplete: function () {
          //window.lockScroll.lock();
          done();
        }
      });
    }



  }

  in({ from, to, trigger, done }) {

    //Check if image cover is loaded
    let firstImageCover = to.querySelectorAll('.cover img')[0];

    firstImageCover.addEventListener('load', function(){

      from.remove();
      window.lockScroll.unlock(0);
      window.zebra.unlock();

      let r = document.querySelector('.remove-when-loaded');
      gsap.to(r, {
        duration:.1,
        delay:.2, //Prevent flick
        opacity:0,
        ease: "explose",
        onCompleteParams:[r],
        onComplete:function (r) {
          if (r)  r.remove();
          done();
        }
      });

    });

  }

}
