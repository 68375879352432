import VideoPlayer from "./videoPlayer";

export default class Video {

  static init() {
    let videos = document.querySelectorAll('[data-video]');
    videos.forEach(function (video) {
      new VideoPlayer(video);
    });

    let video_thumbnails = document.querySelectorAll('[data-video-modal-id]');
    video_thumbnails.forEach(function (video_thumbnail) {
      video_thumbnail.addEventListener('click', function(){
        let dataVideoModalId = video_thumbnail.dataset.videoModalId;
        let v = document.getElementById(dataVideoModalId).querySelector('[data-video]');
        v.dispatchEvent(new MouseEvent('click'));
      });
    });

  }

}
