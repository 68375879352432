import { gsap } from "gsap";

export default class Header{
  constructor(){
    this.header = document.getElementsByTagName('header')[0];
    this.position = 'none';
    this.animation = null;

    document.addEventListener('scroll',function(){
      requestAnimationFrame(function(){
        this.scroll();
      }.bind(this));
    }.bind(this));

  }

  scroll(){
    const doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    let position = 'up';
    if (top > 150){
      position = 'down';
    }
    if (position != this.position){
        this.position = position;
        if (position == 'up') this.up();
        if (position == 'down') this.down();
    }
  }

  up(){
    if (this.animation) this.animation.kill();
    this.animation = gsap.to(this.header.querySelectorAll('.letter'), {
      opacity:1,
      duration: .15,
      stagger:{
        amount:.1
      }
    });

  }

  down(){
    if (this.animation) this.animation.kill();
    this.animation = gsap.to(this.header.querySelectorAll('.letter'), {
      opacity:0,
      duration: .15,
      stagger:{
        amount:.1,
        from:this.header.querySelectorAll('.letter').length
      }
    });
  }

}
