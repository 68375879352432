import Highway from '@dogstudio/highway';
import Swiper from 'swiper';
import Animation from "../../animation";
import { gsap } from "gsap";
import Video from "../../video";


export default class AboutRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    let aboutSwiper = new Swiper('[data-router-view="about"] .swiper-container', {
      spaceBetween: 64,
      loop: true,
      slidesPerView: 'auto',
      touchStartPreventDefault: false,
      preventClicks: false,
      freeMode: true
    });

    Animation.init();
    Video.init();

  }
  onLeave() {

  }
  onEnterCompleted() {
    Animation.observe();
  }
  onLeaveCompleted() {

  }
}
