import { gsap } from "gsap";

export default class Cursor{
  constructor() {
    this.cursor = document.querySelector('.cursor');
    this.dot = document.querySelector('.cursor-dot');
    this.outline = document.querySelector('.cursor-outline');

    document.addEventListener("mousemove", function (e) {
      if (!window.touch.hasTouch){

      requestAnimationFrame(function () {

        gsap.to(this.dot, {
          y: e.clientY,
          x: e.clientX,
          duration:0,
          ease:'linear'
        });

        gsap.to(this.outline, {
          y: e.clientY,
          x: e.clientX,
          duration:1,
          ease:'expo.out'
        });
        /*
        this.dot.style.top = e.clientY + 'px';
        this.dot.style.left = e.clientX + 'px';
        this.outline.style.top = e.clientY + 'px';
        this.outline.style.left = e.clientX + 'px';
    */


        // Link
        /*
        if (e.target.closest('a')) {
          this.cursor.classList.add("link-hovered");
        } else {
          this.cursor.classList.remove("link-hovered");
        }*/

      }.bind(this));
      }
    }.bind(this));
    this.reset();
  }

  reset(){
    let links = document.querySelectorAll('a');
    links.forEach(function(a){
      a.addEventListener('mouseover',function(){
        this.cursor.classList.add("link-hovered");
      }.bind(this));
      a.addEventListener('mouseout',function(){
        this.cursor.classList.remove("link-hovered");
      }.bind(this));
    }.bind(this));

    let cases = document.querySelectorAll('.case-img');
    cases.forEach(function(a){
      a.addEventListener('mouseover',function(){
        this.cursor.classList.add("case-hovered");
      }.bind(this));
      a.addEventListener('mouseout',function(){
        this.cursor.classList.remove("case-hovered");
      }.bind(this));
    }.bind(this));

    let videos = document.querySelectorAll('[data-controls=false], [data-video-modal-id]');
    videos.forEach(function(video){
      video.addEventListener('mouseover',function(){
        this.cursor.classList.remove("video-pause");
        this.cursor.classList.remove("video-play");

        if (video.dataset.status == 'play'){
          this.cursor.classList.add("video-pause");
          this.cursor.classList.remove('loading');
        }else{
          this.cursor.classList.add("video-play");
        }

      }.bind(this));
      video.addEventListener('mouseout',function(){
        this.cursor.classList.remove("video-pause");
        this.cursor.classList.remove("video-play");
      }.bind(this));
    }.bind(this));
  }
}
