import Player from "@vimeo/player";
import Loading from "./loading";
import { gsap } from "gsap";
import CustomEase from "./gsap/src/CustomEase";

export default class VideoPlayer {
  constructor(video){
    this.cinemaMode = false;
    this.cinemoTransition = false;
    this.video = video;
    this.l = this.scrollListener.bind(this);

    this.modal = video.dataset.modal;

    if (video.dataset.video != ''){
      //VIMEO VIDEO
      this.player = new Player(video, {
        id:video.dataset.video,
        controls: screen.width < 1024 || video.dataset.controls,
        loop:false,
        responsive:true,
        quality:'auto',
        autoplay:false,
        autopause:true,
        dnt:true
      });

      this.player.setVolume(1);

      this.player.on('bufferstart', function() {
        Loading.start();
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(screen.width > 1024) {
          if(video.hasAttribute("data-cinema")) {
            this.setCinemaMode();
          }
        }
      }.bind(this));

      this.player.on('play', function() {
        this.video.dataset.status = 'play';
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(screen.width > 1024) {
          if(video.hasAttribute("data-cinema")) {
            this.setCinemaMode();
          }
        }

      }.bind(this));

      this.player.on('pause', function() {
        this.video.dataset.status = 'pause';
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(screen.width > 1024) {
          if(this.video.hasAttribute("data-cinema")) {
            this.setNormalMode();
          }
        }

      }.bind(this));

        this.video.addEventListener('click',function(){
          this.player.getPaused().then(function(paused) {
            if (paused){
              if(this.modal) {
                this.player.setCurrentTime(0);
              }
              this.player.play();
            }else{
              this.player.pause();
            }
          }.bind(this));
        }.bind(this));

   }else{
      //HTML 5 VIDEO
      this.player = this.video.getElementsByTagName('video')[0];

      this.video.addEventListener('click',function(){
        if (this.player.paused){
          this.player.play();
        }else{
          this.player.pause();
        }
      }.bind(this));

      this.player.onplay = function() {
        this.video.dataset.status = 'play';
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(video.hasAttribute("data-cinema")) {
          this.setCinemaMode();
        }
      }.bind(this);

      this.player.onpause = function() {
        this.video.dataset.status = 'pause';
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(this.video.hasAttribute("data-cinema")) {
          this.setNormalMode();
        }
      }.bind(this);

      this.player.onwaiting = function() {
        Loading.start();
        this.video.dispatchEvent(new MouseEvent('mouseover'));
        if(this.video.hasAttribute("data-cinema")) {
          this.setCinemaMode();
        }
      }.bind(this);


    }



  }

  scrollListener(){
    if (this.cinemoTransition || this.cinemaMode) {
      if (this.player){
        this.player.pause();
      } else{
        this.video.pause();
      }
    }
  }

  setCinemaMode(){
    if (this.cinemoTransition || this.cinemaMode){
      return false;
    }

    window.header.down();
    this.cinemoTransition = true;

    document.body.classList.add('cinema-mode');
    if (this.modal){
      this.video.closest('[data-modal-wrapper]').classList.remove('pointer-events-none');
      gsap.to(this.video.closest('[data-modal-wrapper]'), {
        opacity:1,
        duration:.3
      });
    }

    window.zebra.lock();
    window.zebra.setBlack();

    gsap.to(this.video, {
      scale:1.2,
      duration: 1,
      ease: "explose"
    });
    gsap.to(this.video.parentElement.querySelectorAll(".video-mask"), {
      opacity:1,
      duration:.3
    });
    gsap.to(window, {
      scrollTo:{
        y: this.video,
        offsetY: (window.innerHeight - this.video.offsetHeight)/2,
        //autoKill: true
      },
      duration: 1,
      ease: "explose",
      onComplete: function(){
        setTimeout(function(){
          this.cinemoTransition = false;
          this.cinemaMode = true;
          document.addEventListener('scroll', this.l);
          document.addEventListener('click', this.l);
        }.bind(this),200);

      }.bind(this),
    });
  }

  setNormalMode(){
    this.cinemaMode = false;
    this.cinemoTransition = false;

    document.removeEventListener('scroll', this.l);
    window.zebra.unlock();

    if (this.modal){
      this.video.closest('[data-modal-wrapper]').classList.add('pointer-events-none');
      this.video.dispatchEvent(new MouseEvent('mouseout'));

      gsap.to(this.video.closest('[data-modal-wrapper]'), {
        opacity:0,
        duration:.3
      });
    }

    document.body.classList.remove('cinema-mode');
    gsap.to(this.video, {
      scale:1,
      duration: 1,
      ease: "explose"
    });
    gsap.to(this.video.parentElement.querySelectorAll(".video-mask"), {
      opacity:0,
      duration:.3
    });
  }


}
