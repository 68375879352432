export default class Touch {
  constructor() {
    this.hasTouch =  screen.width <= 480;

    if(!this.hasTouch){
      this.hasTouch = 'ontouchstart' in window;
    }

    if (this.hasTouch){
      document.querySelector('body').classList.add('touch');
    }else{
      window.addEventListener('touchstart', function setHasTouch () {
        this.hasTouch = true;
        document.querySelector('body').classList.add('touch');
        document.querySelector('body').classList.remove('no-touch');
        // Remove event listener once fired, otherwise it'll kill scrolling
        // performance
        window.removeEventListener('touchstart', setHasTouch);
      }.bind(this), false);
      document.querySelector('body').classList.add('no-touch');
    }



  }
}
