export default class Zebra {
  constructor() {
    this.offset = 50;
    this.update();
    this.locked = false;
  }

  update(){
    requestAnimationFrame(function(){
      this.updateFrame();
      setTimeout(function(){
        this.update();
      }.bind(this),100);

    }.bind(this));

  }

  updateFrame(){
    if (this.locked) return false;

    const doc = document.documentElement;
    let top = Math.max((window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0) + this.offset, 0);

    let blackElements = document.querySelectorAll('[zebra="black"]');
    for (let i = 0; i < blackElements.length; i++) {
      if (top >= blackElements[i].offsetTop && top <= (blackElements[i].offsetTop + blackElements[i].clientHeight)){
        this.setBlack();
        return true;
      }
    }
    this.setWhite();
  }

  lock(){
    this.locked = true;
  }

  unlock(){
    this.locked = false;
  }

  setBlack(){
    document.querySelector('body').classList.add('zebra-black');
  }

  setWhite(){
    document.querySelector('body').classList.remove('zebra-black');
  }

}
